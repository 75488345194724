import {
  Box,
  Button,
  Fab,
  Grid,
  IconButton,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import * as React from "react";
import { useLoaderData, useNavigate } from "react-router-dom";
import { getAllQuestion, getAllTests, getCategories } from "./FirebaseAPI";
import { Question } from "./models/Question";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";

import EditQuestionDialog from "./EditQuestionDialog";
import { Test } from "./models/Test";

const createDefaultQuestion = (question: Question) => {
  const options = question.options ? [...question.options] : [];
  var size = 4 - options.length;
  for (var i = 0; i < size; i++) {
    options.push("");
  }
  return { ...question, options };
};

const ManageTests = () => {
  const navigate = useNavigate();
  const loadedData = useLoaderData() as Array<any>;
  const [questions, setQuestions] = React.useState(
    loadedData[0] as Map<string, Question>
  );

  const [tests, setTests] = React.useState(loadedData[1] as Map<string, Test>);

  const [open, setOpen] = React.useState(false);
  const [question, setQuestion] = React.useState<Question>(new Question());
  const [questionId, setQuestionId] = React.useState<string>();

  const handleClickOpen = (id?: string) => {
    if (id) {
      navigate("edit/" + id);
    }
  };

  const mobileButton = {
    position: "fixed",
    bottom: 16,
    left: 16,
    display: { md: "none" },
  };

  const desktopButton = {
    position: "fixed",
    bottom: 16,
    right: 16,
    display: { xs: "none", md: "flex" },
  };

  return (
    <Paper>
      <TableContainer component={Paper} sx={{ maxHeight: { md: 500, sx: 1 } }}>
        <Table aria-label="simple table">
          <TableHead key="allTests">
            <TableRow>
              <TableCell>Test</TableCell>
              <TableCell align="left">Active</TableCell>
              <TableCell align="right">Details </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Array.from(tests.entries()).map((entry) => (
              <TableRow
                key={entry[1].name}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {entry[1].name}
                </TableCell>
                <TableCell component="th" scope="row">
                  {entry[1].active ? "Yes" : "No"}
                </TableCell>

                <TableCell align="right">
                  <IconButton
                    color="primary"
                    aria-label="upload picture"
                    component="label"
                    onClick={() => handleClickOpen(entry[0])}
                  >
                    <EditIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Stack
        spacing={2}
        direction="row"
        display="flex"
        justifyContent="flex-end"
        flexDirection="column"
        p={2}
        sx={desktopButton}
      >
        <Button variant="contained" href="manage-tests/add" fullWidth>
          Add a Test
        </Button>
      </Stack>
      <Fab
        color="primary"
        aria-label="add"
        sx={mobileButton}
        href="manage-tests/add"
      >
        <AddIcon />
      </Fab>
    </Paper>
  );
};

export default ManageTests;

export async function loader() {
  const tests = await getAllTests();
  const questions = await getAllQuestion();
  return [questions, tests];
}
