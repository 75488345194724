import {
  Box,
  Button,
  Container,
  Link,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import * as React from "react";

import { login } from "./FirebaseAPI";
import { useNavigate, useLocation } from "react-router-dom";
import HeaderSimple from "./HeaderSimple";

export const Login = () => {
  let navigate = useNavigate();
  let location = useLocation();
  const [error, setError] = React.useState<string | null>();

  const handleSubmit = (event: any) => {
    setError(null);
    const { email, password } = event.target.elements;
    login(email.value, password.value)
      .then(() => {
        console.log("location -> ", location);
        navigate(location?.state?.path || "/", { replace: true });
      })
      .catch(() => {
        setError("Failed to login, try again");
      });
    event.preventDefault();
  };

  return (
    <>
      <HeaderSimple title="Australia Citizenship Help" />
      <Box
        sx={{
          display: "flex",
          backgroundImage: "url(/static/onepirate/appCurvyLines.png)",
          backgroundRepeat: "no-repeat",
        }}
      >
        <Container maxWidth="sm">
          <Box sx={{ mt: 7, mb: 12 }}>
            <Paper sx={{ py: { xs: 4, md: 8 }, px: { xs: 3, md: 6 } }}>
              <React.Fragment>
                <Typography variant="h3" gutterBottom align="center">
                  Sign In
                </Typography>
                <Typography variant="body2" align="center">
                  {"Not a member yet? "}
                  <Link
                    href="/premium-themes/onepirate/sign-up/"
                    align="center"
                    underline="always"
                  >
                    Sign Up here
                  </Link>
                </Typography>
                <Typography
                  variant="body2"
                  align="center"
                  color={"error"}
                  sx={{ mt: 3 }}
                >
                  {error}
                </Typography>
              </React.Fragment>
              <form onSubmit={handleSubmit}>
                <Box onSubmit={handleSubmit} sx={{ mt: 6 }}>
                  <TextField
                    autoComplete="email"
                    autoFocus
                    fullWidth
                    label="Email"
                    margin="normal"
                    name="email"
                    required
                  />
                  <TextField
                    fullWidth
                    required
                    name="password"
                    autoComplete="current-password"
                    label="Password"
                    type="password"
                    margin="normal"
                  />

                  <Button
                    type="submit"
                    sx={{ mt: 3, mb: 2 }}
                    color="secondary"
                    fullWidth
                  >
                    Login
                  </Button>
                </Box>
              </form>
              <Typography align="center">
                <Link
                  underline="always"
                  href="/premium-themes/onepirate/forgot-password/"
                >
                  Forgot password?
                </Link>
              </Typography>
            </Paper>
          </Box>
        </Container>
      </Box>
    </>
  );
};
