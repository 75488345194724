import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  AlertTitle,
  Avatar,
  Box,
  Button,
  Chip,
  Collapse,
  Divider,
  Grid,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import * as React from "react";
import {
  useLoaderData,
  useLocation,
  useNavigate,
  useNavigation,
} from "react-router-dom";
import { getCategories } from "./FirebaseAPI";
import { TestExecution } from "./models/TestExecution";
import { GridExpandMoreIcon } from "@mui/x-data-grid";
import { Check, Close, Refresh } from "@mui/icons-material";
import { green } from "@mui/material/colors";

const optionNames = ["A", "B", "C", "D"];
const colors = new Map([
  ["AUST_VALUE", "#006368"],
  ["AUST_PEOPLE", "#D45125"],
  ["AUST_GOV", "#D2A69C"],
  ["OUR_DEMOCRATIC_BELIEF", "#3F4727"],
]);

const TestResultSummary = () => {
  const categories = useLoaderData() as Map<string, string>;
  const location = useLocation();
  const navigate = useNavigate();
  const result = location.state.result as TestExecution;
  const retest = () => {
    localStorage.removeItem(result.testId);
    navigate("/australian-citizenship-test/" + result.testId);
  };

  return (
    <Paper variant="elevation" sx={{ bgcolor: "#F8FAFB" }}>
      <Grid container justifyContent="center">
        <Grid item md={12} xs={12}>
          <Box
            sx={{
              position: "relative",
              bgcolor: green[500],
              p: { xs: 2, md: 2 },
              pt: 2,
            }}
          >
            <Stack direction="row" spacing={2}>
              <Avatar sx={{ bgcolor: "#283D84" }} variant="rounded">
                <Typography variant="h6" color="white">
                  {result.states.filter((f) => f.correct).length}
                </Typography>
              </Avatar>
              <Typography variant="h6" color="white" sx={{ flex: 1 }}>
                {result.test.name}
              </Typography>
              <Button variant="contained" size="small" onClick={retest}>
                <Refresh /> Re-Test
              </Button>
            </Stack>
          </Box>
          <Box
            sx={{
              position: "relative",
              bgcolor: "white",
              pl: { xs: 3, md: 6 },
              pt: 2,
            }}
          >
            <Stack
              direction="row"
              divider={<Divider orientation="vertical" flexItem />}
            >
              <Typography
                sx={{ textAlign: "center", width: "33%" }}
                color="#999EA6"
              >
                Passing Grade
              </Typography>
              <Typography
                sx={{ textAlign: "center", width: "33%" }}
                color="#999EA6"
              >
                Correct
              </Typography>
              <Typography
                sx={{ textAlign: "center", width: "33%" }}
                color="#999EA6"
              >
                Wrong
              </Typography>
            </Stack>
            <Stack direction="row">
              <Typography
                color="#999EA6"
                sx={{ textAlign: "center", width: "33%" }}
              >
                15/20
              </Typography>
              <Typography
                color="#999EA6"
                sx={{ textAlign: "center", width: "33%" }}
              >
                {result.states.filter((f) => f.correct).length}
              </Typography>
              <Typography
                color="#999EA6"
                sx={{ textAlign: "center", width: "33%" }}
              >
                {result.states.filter((f) => !f.correct).length}
              </Typography>
            </Stack>
          </Box>
        </Grid>
        <Grid item md={8} xs={12}>
          <Box
            sx={{
              position: "relative",
              p: { xs: 3, md: 6 },
              pt: 0,
              pb: 0,
            }}
          >
            <Typography
              variant="subtitle1"
              color="#999EA6"
              sx={{ p: 1, pl: 0 }}
            >
              LIST OF QUESTIONS
            </Typography>
            {result.questions.map((question, index) => {
              const labelId = `checkbox-list-label-${index}`;
              return (
                <Accordion sx={{ width: "100%" }}>
                  <AccordionSummary
                    expandIcon={<GridExpandMoreIcon />}
                    key={index}
                  >
                    <Box
                      sx={{
                        position: "relative",
                      }}
                    >
                      <Typography
                        variant="caption"
                        display="block"
                        color={colors.get(question.heading)}
                        gutterBottom
                      >
                        {categories.get(question.heading)}
                      </Typography>
                      <Typography variant="body2" gutterBottom>
                        {question.description}
                      </Typography>

                      {result.states[index].correct && (
                        <Chip
                          color="success"
                          variant="outlined"
                          size="small"
                          label={
                            <span>
                              <b>Correct</b>
                            </span>
                          }
                          icon={<Check fontSize="small" />}
                        />
                      )}
                      {!result.states[index].correct && (
                        <Chip
                          color="error"
                          variant="outlined"
                          size="small"
                          label={
                            <span>
                              <b>Wrong</b>
                            </span>
                          }
                          icon={<Close fontSize="small" />}
                        />
                      )}
                    </Box>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Collapse in={!result.states[index].correct}>
                      <Alert variant="standard" severity="error">
                        <AlertTitle>
                          You have selected{" "}
                          {optionNames[Number(result.states[index].answer)]} the
                          correct answer was{" "}
                          {optionNames[Number(question?.answer)]}
                        </AlertTitle>
                        Explanation : {question?.explanation}
                      </Alert>
                    </Collapse>
                    <Collapse in={result.states[index].correct}>
                      <Alert variant="standard" severity="success">
                        <AlertTitle>
                          You have selected{" "}
                          {optionNames[Number(result.states[index].answer)]}{" "}
                        </AlertTitle>
                        Explanation : {question?.explanation}
                      </Alert>
                    </Collapse>
                  </AccordionDetails>
                </Accordion>
              );
            })}
          </Box>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default TestResultSummary;

export async function loader() {
  const categories = await getCategories();
  return categories;
}
