import * as React from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import { Divider, Stack } from "@mui/material";

function Copyright(props: { domain: string }) {
  return (
    <Typography variant="body2" align="center">
      {"Copyright © "}
      <Link color="inherit" href={"https://" + props.domain}>
        {props.domain}
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

function Privacy() {
  return (
    <Typography variant="body2" align="center">
      <Link color="inherit" href="/privacy" pr={2}>
        Privacy
      </Link>
      <Link pl={2} color="inherit" href="/terms">
        Terms of Use
      </Link>
    </Typography>
  );
}

interface FooterProps {
  description: string;
  title: string;
  domain: string;
}

export default function Footer(props: FooterProps) {
  const { description, title, domain } = props;

  return (
    <Box component="footer" sx={{ bgcolor: "#262626", py: 6, color: "white" }}>
      <Container maxWidth="lg">
        <Typography variant="h6" align="center" gutterBottom>
          {title}
        </Typography>
        <Typography variant="subtitle1" align="center" component="p">
          {description}
        </Typography>
        <Privacy />
        <Copyright domain={domain} />
      </Container>
    </Box>
  );
}
