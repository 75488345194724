import {
  Alert,
  AlertTitle,
  Button,
  Chip,
  Collapse,
  Grid,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  Radio,
  Stack,
  Typography,
} from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import { Box } from "@mui/system";
import * as React from "react";
import { useLoaderData, useNavigate, useParams } from "react-router-dom";
import { getPracticeTest, getQuestion, getTest, logevent } from "./FirebaseAPI";
import { Question } from "./models/Question";
import LinearProgressWithLabel from "./LinearProgressWithLabel";
import { SubmissionState, TestExecution } from "./models/TestExecution";
import { Check, Close, Refresh } from "@mui/icons-material";
import { GridCheckCircleIcon } from "@mui/x-data-grid";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import { green } from "@mui/material/colors";

const optionNames = ["A", "B", "C", "D"];

const PracticeTest = () => {
  const execution = useLoaderData() as TestExecution;
  const navigate = useNavigate();
  const [currentQuestion, setCurrentQuestion] = React.useState(
    execution.questions[execution.currentQuestion]
  );
  var questionState = execution.states[execution.currentQuestion];

  const [progress, setProgress] = React.useState(execution.states.length);
  const [state, setState] = React.useState(questionState);

  const [checked, setChecked] = React.useState<undefined | string>(
    questionState && questionState.answer
  );

  const handleToggle = (value: string) => () => {
    if (state && state.submitted) {
      return;
    }
    setChecked(value);
  };

  const submit = () => {
    let state = new SubmissionState();
    state.answer = checked;
    state.submitted = true;
    state.correct = currentQuestion.answer == checked;
    execution.states[execution.currentQuestion] = state;

    setState(state);
    localStorage.setItem(
      execution.testId,
      btoa(encodeURIComponent(JSON.stringify(execution)))
    );
    setProgress(progress + 1);
  };

  const next = () => {
    execution.currentQuestion++;
    var question: Question = execution.questions[execution.currentQuestion];
    if (question == undefined) {
      getQuestion(execution.test.questions[execution.currentQuestion]).then(
        (result) => {
          execution.questions[execution.currentQuestion] = result;
          setCurrentQuestion(result);
        }
      );
    } else {
      setCurrentQuestion(question);
    }
    setState(execution.states[execution.currentQuestion]);
    setChecked(
      execution.states[execution.currentQuestion] &&
        execution.states[execution.currentQuestion].answer
    );
  };

  const textStyle = (index: string) => {
    if (state?.submitted && currentQuestion.answer == index) {
      return {
        color: "white",
        bgcolor: "lightgreen",
        mt: 1,
        mb: 1,
      };
    } else if (state?.submitted) {
      return {
        textDecoration: "red line-through",
        color: "red",
        mt: 1,
        mb: 1,
        bgcolor: "background.paper",
      };
    }
    return {
      textDecorationColor: "primary.main",
      mt: 1,
      mb: 1,
      bgcolor: "background.paper",
      borderRadius: "5px",
    };
  };

  const checkBoxStyle = (index: string) => {
    if (state?.submitted && currentQuestion.answer == index) {
      return { fill: "white" };
    }
    return {};
  };

  const score = () => {
    return (
      (execution.states.filter((f) => f.correct).length /
        execution.test.questions.length) *
      100
    ).toFixed(0);
  };

  const passed = () => {
    return Number(score()) >= 75 && progress == execution.test.questions.length;
  };

  const failed = () => {
    return Number(score()) < 75 && progress == execution.test.questions.length;
  };

  const summary = () => {
    navigate("/result", { state: { result: execution } });
  };

  const restart = () => {
    localStorage.removeItem(execution.testId);
    //window.location.reload();
    execution.currentQuestion = 0;
    execution.states = new Array<SubmissionState>();
    setProgress(0);
    setCurrentQuestion(execution.questions[0]);
    setState(new SubmissionState());
    setChecked(undefined);
  };

  return (
    <Paper variant="elevation" sx={{ bgcolor: "#F8FAFB" }}>
      <Grid container justifyContent="center" alignItems="center">
        <Grid item md={8} xs={12}>
          <Box
            sx={{
              position: "relative",
              bgcolor: green[500],
              p: { xs: 2, md: 2 },
              pt: 2,
            }}
          >
            <Stack direction="row" spacing={2}>
              <Typography variant="h6" color="white" sx={{ flex: 1 }}>
                {execution.test.name}
              </Typography>
              <Button variant="contained" size="small" onClick={restart}>
                <Refresh /> Re-Start
              </Button>
            </Stack>
          </Box>
          <Box
            sx={{
              position: "relative",
              pt: 0,
              pl: 0,
            }}
          >
            <LinearProgressWithLabel
              value={(progress / execution.test.questions.length) * 100}
            />
            <Collapse in={failed()}>
              <Alert
                severity="error"
                action={
                  <Button variant="contained" size="small" onClick={summary}>
                    Summary
                  </Button>
                }
              >
                <AlertTitle>FAILED</AlertTitle>
                Unfortunately you have failed the test - you have scored{" "}
                {score()}%
              </Alert>
            </Collapse>
            <Collapse in={passed()}>
              <Alert
                variant="filled"
                action={
                  <Button variant="contained" size="small" onClick={summary}>
                    Summary
                  </Button>
                }
              >
                <AlertTitle>PASSED</AlertTitle>
                Congratulations! you have passed the test - you have scored{" "}
                {score()}%
              </Alert>
            </Collapse>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                p: 2,
              }}
            >
              <Chip
                color="success"
                variant="outlined"
                label={
                  <span>
                    <b>Correct:</b>{" "}
                    {execution.states.filter((f) => f.correct).length}
                  </span>
                }
                icon={<Check fontSize="small" />}
              />
              &nbsp;
              <Chip
                color="error"
                variant="outlined"
                label={
                  <span>
                    <b>Wrong:</b>{" "}
                    {execution.states.filter((f) => !f.correct).length}
                  </span>
                }
                icon={<Close fontSize="small" />}
              />
            </Box>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Box sx={{ width: "100%", pl: { xs: 3, md: 3 } }}>
                <Typography variant="subtitle1" color="#999EA6" gutterBottom>
                  QUESTION {execution.currentQuestion + 1} OF{" "}
                  {execution.test.questions.length}
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              position: "relative",
              p: { xs: 3, md: 3 },
              pt: 0,
              pb: 0,
            }}
          >
            <Typography variant="h6" color="#303236" gutterBottom>
              {currentQuestion.description}
            </Typography>
            <List sx={{ width: "100%" }}>
              {currentQuestion.options.map((value, index) => {
                const labelId = `checkbox-list-label-${value}`;
                return (
                  <ListItem
                    key={value}
                    disablePadding
                    sx={textStyle("" + index)}
                  >
                    <ListItemButton
                      role={undefined}
                      onClick={handleToggle("" + index)}
                      dense
                    >
                      <ListItemIcon>
                        <Radio
                          edge="start"
                          icon={
                            <RadioButtonUncheckedIcon
                              sx={checkBoxStyle("" + index)}
                            />
                          }
                          sx={checkBoxStyle("" + index)}
                          checkedIcon={
                            <GridCheckCircleIcon
                              sx={checkBoxStyle("" + index)}
                            />
                          }
                          checked={checked == "" + index}
                          tabIndex={-1}
                          disableRipple
                          inputProps={{ "aria-labelledby": labelId }}
                          disabled={state?.submitted}
                        />
                      </ListItemIcon>
                      <ListItemText
                        id={labelId}
                        primary={optionNames[index] + ": " + value}
                      />
                    </ListItemButton>
                  </ListItem>
                );
              })}
            </List>
          </Box>
          <Box
            sx={{
              position: "relative",
              pl: { xs: 3, md: 3 },
              pr: { md: 0 },
            }}
          >
            <Button
              variant="contained"
              color="success"
              endIcon={<SendIcon />}
              onClick={submit}
              sx={{
                display:
                  checked == undefined || (state && state.submitted)
                    ? "none"
                    : "inline-flex",
              }}
            >
              SUBMIT
            </Button>
            <Button
              variant="contained"
              endIcon={<SendIcon />}
              onClick={next}
              sx={{
                display:
                  state &&
                  state.submitted &&
                  progress < execution.test.questions.length
                    ? "inline-flex"
                    : "none",
              }}
            >
              NEXT
            </Button>
          </Box>
          <Box
            sx={{
              position: "relative",
              p: { xs: 3, md: 3 },
              pl: { xs: 0 },
              pr: { xs: 0 },
            }}
          >
            <Stack sx={{ width: "100%" }} spacing={2}>
              <Collapse in={state?.submitted && !state?.correct}>
                <Alert variant="standard" severity="error">
                  <AlertTitle>
                    You have selected {optionNames[Number(state?.answer)]} the
                    correct answer was{" "}
                    {optionNames[Number(currentQuestion?.answer)]}
                  </AlertTitle>
                  Explanation : {currentQuestion?.explanation}
                </Alert>
              </Collapse>
              <Collapse in={state?.submitted && state?.correct}>
                <Alert variant="standard" severity="success">
                  <AlertTitle>
                    You have selected {optionNames[Number(state?.answer)]}{" "}
                  </AlertTitle>
                  Explanation : {currentQuestion?.explanation}
                </Alert>
              </Collapse>
            </Stack>
          </Box>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default PracticeTest;

export async function loader(id?: string) {
  const testId = id ? id : "A4pHp0dJR0IP7KRTYAxE";
  const practiceTest = await getTest(testId);
  logevent("open_test", { testId: testId, test_name: practiceTest?.name });
  const savedStat = localStorage.getItem(testId);
  const status = JSON.parse(
    savedStat ? decodeURIComponent(atob(savedStat)) : "{}"
  ) as TestExecution;
  const questions = new Array<Question>();
  if (!status.test) {
    const firstQuestionId = practiceTest.questions[0] as string;
    const firstQuestion = await getQuestion(firstQuestionId);
    questions.push(firstQuestion);
  } else {
    if (status.currentQuestion < practiceTest.questions.length - 1) {
      status.currentQuestion++;
      const questionId: string =
        practiceTest.questions[status?.currentQuestion];
      const question = await getQuestion(questionId);
      status.questions.push(question);
    }
  }

  //console.log(status);
  //return { test: practiceTest, firstQuestion: firstQuestion };
  return {
    test: practiceTest,
    questions: status?.questions || questions,
    currentQuestion: status?.currentQuestion || 0,
    states: status?.states || new Array<SubmissionState>(),
    testId: id ? id : "A4pHp0dJR0IP7KRTYAxE",
  };
}
