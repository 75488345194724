import { styled } from "@mui/system";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/material";

const TopicStyle = styled("li")({
  margin: 10,
});

const Topic = ({
  children,
  heading,
}: {
  children: string;
  heading: string;
}) => (
  <TopicStyle>
    <Typography variant="h6">{heading}</Typography>{" "}
    <Typography variant="body1" paragraph>
      {children}
    </Typography>
  </TopicStyle>
);

const HowToPrepare = () => {
  return (
    <Box
      sx={{
        p: { xs: 3, md: 5 },
      }}
    >
      <Typography variant="h4" gutterBottom sx={{ textAlign: "center" }}>
        How to prepare for Australian Citizenship Test
      </Typography>
      <Typography variant="body1" paragraph>
        The Australian Citizenship Test is an important step towards becoming a
        citizen of Australia. It is designed to assess whether you have an
        adequate knowledge of Australia. It covers a variety of topics related
        to Australia's history, government, culture, and values. Some of the
        topics that may be covered on the test include:
      </Typography>
      <ul>
        <Topic heading="The Australian Constitution:">
          The Australian Constitution lays out the framework for Australia's
          government and legal system. Understanding the Constitution is
          important for becoming a citizen and participating in civic life in
          Australia.
        </Topic>
        <Topic heading="Australian history:">
          Knowing about Australia's history is crucial for understanding its
          culture, values, and traditions. Topics that may be covered on the
          test include the arrival of Europeans, Federation, the two World Wars,
          and the development of modern Australia.
        </Topic>
        <Topic heading="Australian geography:">
          Familiarity with the geography of Australia is important for
          understanding its climate, natural resources, and regional diversity.
          Topics that may be covered on the test include the states and
          territories, major cities, and natural landmarks.
        </Topic>
        <Topic heading="Australian government:">
          Knowing about the Australian government is essential for understanding
          how decisions are made and how laws are enforced in the country.
          Topics that may be covered on the test include the three branches of
          government, the role of the Prime Minister, and the rights and
          responsibilities of citizens.
        </Topic>
        <Topic heading="Australian values:">
          Understanding Australian values is key to being a good citizen and
          contributing to society. Topics that may be covered on the test
          include respect for the law, freedom of speech, equality, and the
          importance of community.
        </Topic>
        <Topic heading="Australian symbols:">
          Knowing about the symbols of Australia is important for understanding
          its identity and heritage. Topics that may be covered on the test
          include the Australian flag, the national anthem, and national
          holidays such as ANZAC Day and Australia Day.
        </Topic>
        <Topic heading="Australian multiculturalism:">
          A Australia is a diverse country with many different cultures,
          languages, and religions. Understanding and respecting this diversity
          is important for being a good citizen. Topics that may be covered on
          the test include the benefits of multiculturalism, anti-discrimination
          laws, and the importance of tolerance.
        </Topic>
      </ul>
      <Typography variant="body1" paragraph>
        By studying these topics, you can increase your knowledge and
        understanding of Australia, and be better prepared for the Australian
        Citizenship Test. These topics are covered in the Our Common Bond
        booklet or podcasts available on the department of immigration and
        homeaffairs website. These topics are divided into four sections.
        <ul>
          <li>Australia and its people</li>
          <li>Australia's democratic beliefs, rights and liberties</li>
          <li>government and the law in Australia</li>
          <li>
            an understanding and commitment to Australian values based on
            freedom, respect and equality
          </li>
        </ul>
      </Typography>
      <Typography variant="body1" paragraph>
        Once you have read through and familarised yourself with the relevant
        topics in the testable section of Our Common Bond booklet. You can
        practice using the{" "}
        <a href="/australian-citizenship-test">free practice tests </a>
        provided on our webite. This will give you confidence to pass the test.
        We are continiously improving and would appreciate any feedback on{" "}
        <a href="mailto:support@futurecitizens.com.au">
          support@futurecitizens.com.au
        </a>
        .
      </Typography>
      <Typography variant="body1" paragraph>
        You only need 75% to pass the test but you have to answer all the
        questions related to <b>Australian Values</b> correctly to pass the
        test. So its important to focus more on <b>Australian Values</b>.
      </Typography>
    </Box>
  );
};

export default HowToPrepare;
