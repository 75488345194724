import * as React from "react";
import { loader as loadPracticeTest } from "./PracticeTest";
import { loader as loadAllQuestions } from "./ManageQuestions";
import { loader as loadAllTests } from "./ManageTests";

import {
  RouterProvider,
  createRoutesFromElements,
  createBrowserRouter,
  Route,
} from "react-router-dom";
import Home from "./Home";
import AboutUS from "./AboutUS";
import FreePracticeTest from "./PracticeTest";
import ManageQuestions from "./ManageQuestions";
import { Login } from "./Login";
import PrivateRoute from "./PrivateRoute";
import { RootLayout } from "./RootLayout";
import ManageTests from "./ManageTests";
import EditTest from "./EditTest";
import ListAllTest from "./ListAllTest";
import TestResultSummary from "./TestResultSummary";
import Privacy from "./Privacy";
import Terms from "./Terms";
import { getCategories } from "./FirebaseAPI";
import Faq from "./Faq";
import Articles from "./Articles";

export default function Main() {
  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route path="/">
        <Route path="/" element={<RootLayout />}>
          <Route path="/about" element={<AboutUS />}></Route>
          <Route index path="/" element={<Home />}></Route>
          <Route
            path="/admin/manage-questions"
            loader={loadAllQuestions}
            element={
              <PrivateRoute path="/admin/manage-questions">
                <ManageQuestions />
              </PrivateRoute>
            }
          ></Route>
          <Route
            path="/admin/manage-tests"
            loader={loadAllTests}
            element={
              <PrivateRoute path="/admin/manage-tests">
                <ManageTests />
              </PrivateRoute>
            }
          ></Route>
          <Route
            path="/admin/manage-tests/edit/:id"
            loader={loadAllQuestions}
            element={
              <PrivateRoute path="/admin/manage-tests/edit/:id">
                <EditTest />
              </PrivateRoute>
            }
          ></Route>
          <Route
            path="/admin/manage-tests/add"
            loader={loadAllQuestions}
            element={
              <PrivateRoute path="/admin/manage-tests/add">
                <EditTest />
              </PrivateRoute>
            }
          ></Route>
          <Route
            path="/australian-citizenship-test/free-practice-test"
            loader={({ params }) => loadPracticeTest()}
            element={<FreePracticeTest />}
          ></Route>
          <Route
            path="/australian-citizenship-test/:id"
            loader={({ params }) => loadPracticeTest(params.id)}
            element={<FreePracticeTest />}
          ></Route>
          <Route
            path="/australian-citizenship-test"
            loader={loadAllTests}
            element={<ListAllTest />}
          ></Route>
          <Route
            path="/result"
            element={<TestResultSummary />}
            loader={getCategories}
          ></Route>
          <Route path="/faq" element={<Faq />}></Route>
          <Route path="/privacy" element={<Privacy />}></Route>
          <Route path="/terms" element={<Terms />}></Route>
          <Route path="/articles" element={<Articles />}></Route>
        </Route>
        <Route path="/login" element={<Login />}></Route>
      </Route>
    )
  );

  return <RouterProvider router={router} />;
}
