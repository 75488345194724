import React, { useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { logevent } from "../FirebaseAPI";
import { useNavigate } from "react-router-dom";

export default function DonationDialog(props: {
  open: boolean;
  close: Function;
  id?: string;
}) {
  const navigate = useNavigate();

  const handleMayBe = () => {
    logevent("open_donate_maybe", { link: "square" });
    localStorage.setItem("coffee_answer", "maybe");
    navigate("/australian-citizenship-test/" + props.id);
    props.close();
  };

  const handleDonate = () => {
    logevent("open_donate_yes", { link: "square" });
    localStorage.setItem("coffee_answer", "yes");
    window.location.href = "https://square.link/u/6V94g2Gc";
  };

  const handleSave = () => {
    logevent("open_donate_no", { link: "square" });
    localStorage.setItem("coffee_answer", "no");
    navigate("/australian-citizenship-test/" + props.id);
    props.close();
  };

  return (
    <div>
      <Dialog open={props?.open}>
        <DialogTitle>Buy me a coffee</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description1">
            🎉 Hey there! Got a moment for some caffeinated excitement? ☕️
          </DialogContentText>
          <br></br>
          <DialogContentText id="alert-dialog-description2">
            We're on a mission to brew up some goodness and bring smiles to
            faces.
            <p></p>
            We're a group of passionate individuals working on
            <a href="/"> www.futurecitizens.com.au </a>and we could use your
            support, even if it's just the cost of a cup of coffee.
            <p>
              Imagine the power of your spare change, transformed into a warm
              cup of motivation for our team. It's like adding an extra shot of
              inspiration to our day!
            </p>
            <p>
              So, how about it? Care to share your coffee money with us and help
              fuel our mission? Every sip counts! Cheers! 🌟
            </p>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDonate}>A big Yes :)</Button>
          <Button onClick={handleMayBe}>Later</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
