import { Paper } from "@mui/material";
import { Box } from "@mui/system";
import { useEffect, useState } from "react";

const createMarkup = (html: string) => {
  return { __html: html };
};

const Terms = () => {
  const [htmlDoc, setHtmlDoc] = useState("");

  useEffect(() => {
    fetch("/terms.html")
      .then((response) => response.text())
      .then(setHtmlDoc);
  }, []);

  return (
    <Paper sx={{ bgcolor: "#F8FAFB" }}>
      <Box
        sx={{
          p: { xs: 3, md: 10 },
        }}
      >
        <div dangerouslySetInnerHTML={createMarkup(htmlDoc)}></div>
      </Box>
    </Paper>
  );
};

export default Terms;
