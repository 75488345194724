import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import { styled, lighten, darken } from "@mui/system";
import {
  Autocomplete,
  Button,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Paper,
} from "@mui/material";
import { getAllQuestionsCount, getTest, saveTest } from "./FirebaseAPI";
import { useLoaderData, useParams } from "react-router-dom";
import { Test } from "./models/Test";
import { Box } from "@mui/system";
import { Question } from "./models/Question";
import DeleteIcon from "@mui/icons-material/Delete";
import { Category } from "./models/Category";

const GroupHeader = styled("div")(({ theme }) => ({
  position: "sticky",
  top: "-8px",
  padding: "4px 10px",
  color: theme.palette.primary.main,
  backgroundColor:
    theme.palette.mode === "light"
      ? lighten(theme.palette.primary.light, 0.85)
      : darken(theme.palette.primary.main, 0.8),
}));

const GroupItems = styled("ul")({
  padding: 0,
});

export default function EditTest() {
  const { id } = useParams();

  const [testId, setTestId] = useState<string | undefined>(id);
  const [test, setTest] = useState<Test>(new Test());
  const [questionCount, setQuestionCount] = useState<Map<string, number>>();
  const loadedData = useLoaderData() as Array<any>;
  const [questions, setQuestions] = React.useState(
    loadedData[0] as Map<string, Question>
  );
  const categories = loadedData[1] as Map<string, Category>;
  const [selected, setSelected] = React.useState<null | string>();

  React.useEffect(() => {
    if (id) {
      getTest(id).then(setTest);
    }
    getAllQuestionsCount().then(setQuestionCount);
  }, []);

  const handleTest = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setTest({
      ...test,
      name: e.target.value,
    });
    if (!id) {
      setTestId(e.target.value.toLowerCase().trim().replaceAll(" ", "-"));
    }
  };

  const addQuestion = () => {
    console.log("Adding questions..");
    if (selected) {
      let questions = [...test.questions, selected];
      setTest({
        ...test,
        questions,
      });
      setSelected(null);
      console.log("Added questions..");
    }
  };

  const selectQuestion = (option: NonNullable<string | [string, Question]>) => {
    if (option) {
      setSelected(option[0]);
    }
  };

  const removeQuestion = (questionId: string) => {
    if (questionId) {
      const questions = test.questions.filter((f) => f != questionId);
      setTest({
        ...test,
        questions,
      });
    }
  };

  const handleSaveTest = () => {
    saveTest(test, testId).then(setTestId);
  };

  const reset = () => {};

  return (
    <div>
      <Paper>
        <Grid container>
          <Grid item md={8} xs={12}>
            <Box
              sx={{
                position: "relative",
                p: { xs: 3, md: 3 },
                pl: { xs: 2 },
                pr: { xs: 2 },
              }}
            >
              <TextField
                autoFocus
                margin="dense"
                id="Name"
                label="Question"
                fullWidth
                onChange={handleTest}
                defaultValue={test.name}
                value={test.name}
              />
              <Box
                sx={{
                  position: "relative",
                  pt: { xs: 2 },
                  pb: { xs: 2 },
                }}
              >
                <Autocomplete
                  onChange={(event, option) => selectQuestion(option)}
                  freeSolo
                  id="free-solo-2-demo"
                  disableClearable
                  groupBy={(option) => (option[1] as Question).heading}
                  options={Array.from(questions.entries())
                    .filter(
                      (entry) =>
                        test?.questions?.indexOf(entry[0]) < 0 ||
                        !test?.questions
                    )
                    .sort((a, b) => -b[1].heading.localeCompare(a[1].heading))}
                  getOptionLabel={(option) =>
                    "(" +
                    (questionCount?.get(option[0]) || 0) +
                    ") " +
                    (option[1] as Question).description
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Search Questions"
                      InputProps={{
                        ...params.InputProps,
                        type: "search",
                      }}
                    />
                  )}
                  renderGroup={(params) => (
                    <li key={params.key}>
                      <GroupHeader>
                        {categories.get(params.group)?.label}
                      </GroupHeader>
                      <GroupItems>{params.children}</GroupItems>
                    </li>
                  )}
                />
              </Box>
              <Button
                color="primary"
                aria-label="add"
                variant="outlined"
                onClick={() => addQuestion()}
                sx={{ width: "100%" }}
              >
                Add Selected Question
              </Button>

              <List sx={{ width: "100%" }}>
                {test?.questions?.map((q) => {
                  const question = questions?.get(q);
                  return (
                    <ListItem key={q} disablePadding>
                      <ListItemButton role={undefined} dense>
                        <ListItemText
                          id={q}
                          primary={question?.description}
                          secondary={
                            categories.get(question?.heading || "")?.label +
                            ", " +
                            question?.tags?.map((tag) => tag).join(", ")
                          }
                        />
                      </ListItemButton>
                      <IconButton
                        color="primary"
                        aria-label="upload picture"
                        component="label"
                        onClick={(e) => removeQuestion(q)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </ListItem>
                  );
                })}
              </List>
              <Button
                color="primary"
                aria-label="add"
                variant="outlined"
                onClick={() => handleSaveTest()}
                sx={{ width: "100%" }}
              >
                Save Test
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
}
