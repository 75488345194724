import * as React from "react";
import Toolbar from "@mui/material/Toolbar";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { logevent, logout } from "./FirebaseAPI";
import { useAuth } from "./Auth";
import { Box, Menu, MenuItem } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { Link, useNavigate } from "react-router-dom";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import AusIcon from "./Future.png";

interface HeaderProps {
  sections: ReadonlyArray<{
    title: string;
    url: string;
  }>;
  title: string;
}

export default function Header(props: HeaderProps) {
  const navigate = useNavigate();
  const { currentUser } = useAuth();
  const { sections, title } = props;
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(
    null
  );

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = (url: string) => {
    setAnchorElNav(null);
    navigate(url);
  };

  const openDonate = () => {
    logevent("open_donate", { link: "paypal" });
    window.location.href = "https://square.link/u/6V94g2Gc";
  };

  return (
    <React.Fragment>
      <Toolbar
        sx={{
          borderBottom: 1,
          borderColor: "divider",
          bgcolor: "#283D84",
          color: "white",
          maxHeight: "10px",
        }}
      >
        <MailOutlineIcon sx={{ fill: "#5BA344" }} />

        <Typography
          variant="subtitle1"
          color="inherit"
          align="left"
          noWrap
          sx={{ flex: 1, ml: 2 }}
        >
          {title}
        </Typography>
        <Button
          size="small"
          variant="contained"
          color="success"
          onClick={openDonate}
        >
          Buy me a coffee
        </Button>
        <Button
          variant="outlined"
          size="small"
          href="/login"
          sx={{ display: "none" }}
        >
          Sign in
        </Button>
        <Button
          variant="outlined"
          size="small"
          onClick={logout}
          sx={{ display: !currentUser ? "none" : "inherit" }}
        >
          Logout
        </Button>
      </Toolbar>
      <Box sx={{ display: { xs: "flex", md: "none" } }}>
        <IconButton
          size="large"
          aria-label="account of current user"
          aria-controls="menu-appbar"
          aria-haspopup="true"
          onClick={handleOpenNavMenu}
          color="inherit"
        >
          <MenuIcon />
        </IconButton>
        <Menu
          id="menu-appbar"
          anchorEl={anchorElNav}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          keepMounted
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          open={Boolean(anchorElNav)}
          onClose={handleCloseNavMenu}
          sx={{
            display: { xs: "block", md: "none" },
          }}
        >
          {sections.map((page) => (
            <MenuItem
              key={page.title}
              onClick={(event) => handleCloseNavMenu(page.url)}
              href={page.url}
            >
              <Typography textAlign="center">{page.title}</Typography>
            </MenuItem>
          ))}
        </Menu>
        <img src={AusIcon} alt="Logo" height={50} />
      </Box>
      <Toolbar
        component="nav"
        variant="dense"
        sx={{
          justifyContent: "right",
          overflowX: "auto",
          display: { xs: "none", md: "flex", minHeight: "80px" },
        }}
      >
        <Link to={"/"}>
          <img src={AusIcon} alt="Logo" height={80} />
        </Link>
        <Typography
          variant="h5"
          color="inherit"
          align="left"
          noWrap
          sx={{ flex: 1, ml: 2, color: "#5BA344" }}
        ></Typography>
        {sections.map((section) => (
          <Button key={section.title} href={section.url}>
            {section.title}
          </Button>
        ))}
      </Toolbar>
    </React.Fragment>
  );
}
