import { Question } from "./models/Question";
import { initializeApp } from "firebase/app";
import { getAuth, signInWithEmailAndPassword, signOut } from "firebase/auth";
import { getAnalytics, logEvent } from "firebase/analytics";

import {
  addDoc,
  collection,
  doc,
  getDoc,
  getDocs,
  getFirestore,
  setDoc,
} from "firebase/firestore";
import { Test } from "./models/Test";
import { Category } from "./models/Category";

const firebaseConfig = {
  apiKey: "AIzaSyAEtgv8NSvdNO5K5BYrcBjUKUGAuw0r5o0",
  authDomain: "sabjimandi-1c3ef.firebaseapp.com",
  databaseURL: "https://sabjimandi-1c3ef.firebaseio.com",
  projectId: "sabjimandi-1c3ef",
  storageBucket: "sabjimandi-1c3ef.appspot.com",
  messagingSenderId: "379405735706",
  appId: "1:379405735706:web:d2634ae1c331031a57bea3",
  measurementId: "G-G0BTJKZ0DY",
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const analytics = getAnalytics();

export const auth = getAuth(app);

export const logevent = (eventName: string, event: any) => {
  logEvent(analytics, eventName, event);
};

export const login = async (email: string, password: string) => {
  return await signInWithEmailAndPassword(auth, email, password);
};

export const logout = async () => {
  return signOut(auth);
};

export async function getPracticeTest(): Promise<Test> {
  return getTest("A4pHp0dJR0IP7KRTYAxE");
}

export async function getQuestion(id: string): Promise<Question> {
  const docRef = doc(db, "questions", id);
  const docSnap = await getDoc(docRef);
  return docSnap.data() as Question;
}

export async function getAllQuestion(): Promise<Map<string, Question>> {
  const querySnapshot = await getDocs(collection(db, "questions"));
  const questions = new Map<string, Question>();
  querySnapshot.forEach((doc) => {
    questions.set(doc.id, doc.data() as Question);
  });
  return questions;
}

export async function getTest(id: string): Promise<Test> {
  const docRef = doc(db, "tests", id);
  const docSnap = await getDoc(docRef);
  return docSnap.data() as Test;
}

export async function getAllTests(): Promise<Map<string, Test>> {
  const querySnapshot = await getDocs(collection(db, "tests"));
  const tests = new Map<string, Test>();
  querySnapshot.forEach((doc) => {
    tests.set(doc.id, doc.data() as Test);
  });
  return tests;
}

export async function getAllQuestionsCount(): Promise<Map<string, number>> {
  const querySnapshot = await getDocs(collection(db, "tests"));
  const questionCount = new Map<string, number>();
  querySnapshot.forEach((doc) => {
    (doc.data() as Test).questions.forEach((q) => {
      questionCount.set(q, (questionCount.get(q) || 0) + 1);
    });
  });
  return questionCount;
}

export async function getCategories(): Promise<Map<string, string>> {
  return new Map([
    ["AUST_VALUE", "Australian values"],
    ["AUST_PEOPLE", "Australia and its people"],
    ["AUST_GOV", "Government and the law in Australia"],
    ["OUR_DEMOCRATIC_BELIEF", "Australia's democratic beliefs and liberties"],
  ]);
}

export async function getAllCategories(): Promise<Map<string, Category>> {
  return new Map([
    [
      "AUST_VALUE",
      {
        label: "Australian values",
        tags: [
          "Our values",
          "Commitment to the Rule of Law",
          "Parliamentary democracy",
          "Freedom of speech",
          "Freedom of association",
          "Freedom of religion",
          "Equality of all people under the law",
          "Equality of opportunity and a 'fair go'",
          "Mutual respect and tolerance for others",
          "Our community",
          "Making a contribution",
          "Compassion for those in need",
          "English as the national language",
          "Helping to keep our society safe",
          "Loyalty to Australia",
        ],
      },
    ],
    [
      "AUST_PEOPLE",
      {
        label: "Australia and its people",
        tags: [
          "Aboriginal and Torres Strait Islander peoples",
          "Early days of European setlement",
          "The nation of Australia",
          "Australia's states and territories",
          "The states",
          "The territories",
          "Traditions",
          "Important days for Australians",
          "Australia Day",
          "Anzac Day",
          "Australia's flags",
          "The Australian National Flag",
          "The Australian Aboriginal Flag",
          "The Torres Strait Islander Flag",
          "Australia's symbols",
          "Commonwealth Coat of Arms",
          "Australia's national flower",
          "Australia's national colours",
          "Australia's national gemstone",
          "Australia's national anthem",
        ],
      },
    ],
    [
      "AUST_GOV",
      {
        label: "Government and the law in Australia",
        tags: [
          "How do I have my say?",
          "Voting",
          "Raising maters with your representatives",
          "How did we establish our system of government?",
          "Federation",
          "The Australian Constitution",
          "How is the power of government controlled?",
          "Legislative power",
          "Executive power",
          "Judicial power",
          "Who is Australia's Head of State?",
          "Constitutional monarchy",
          "The role of the Governor-General",
          "Who are some of Australia's leaders?",
          "How is Australia governed?",
          "The Australian Government",
          "The House of Representatives",
          "The Senate",
          "State and territory government",
          "Local government",
          "What do the three levels of government do?",
          "What role do political parties play in the way Australia is governed?",
          "How is the Australian Government formed?",
          "How are laws made?",
          "How are laws enforced?",
          "The courts",
          "The police",
          "Judges and magistrates",
          "Juries",
          "Criminal offences in Australia",
          "Traffic offences",
        ],
      },
    ],
    [
      "OUR_DEMOCRATIC_BELIEF",
      {
        label: "Australia's democratic beliefs and liberties",
        tags: [
          "Our democratic beliefs",
          "Parliamentary democracy",
          "The Rule of Law",
          "Living peacefully",
          "Respect for all individuals",
          "Our freedoms",
          "Freedom of speech and expression",
          "Freedom of association",
          "Freedom of religion",
          "Our equalities",
          "Gender equality",
          "Equality of opportunity and a ‘fair go’",
          "Responsibilities and privileges of Australian citizenship",
          "Responsibilities",
          "Privileges",
          "Participating in Australian society",
        ],
      },
    ],
  ]);
}

export async function saveQuestion(question: Question, id?: string) {
  if (id) {
    await setDoc(doc(db, "questions", id), question);
  } else {
    await addDoc(collection(db, "questions"), question);
  }
}

export async function saveTest(test: Test, id?: string): Promise<string> {
  if (id) {
    const docRef = await setDoc(doc(db, "tests", id), test);
    return id;
  } else {
    const docRef = await addDoc(collection(db, "tests"), test);
    return docRef.id;
  }
}
