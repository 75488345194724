import { Question } from "./Question";
import { Test } from "./Test";

export class TestExecution {
  "test": Test;
  "questions": Array<Question>;
  "states": Array<SubmissionState>;
  "currentQuestion": number;
  "testId": string;
}

export class SubmissionState {
  "answer"?: string;
  "submitted": boolean = false;
  "correct": boolean;
}
