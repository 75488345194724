import { styled } from "@mui/system";
import Typography from "@mui/material/Typography";
import { Box, Paper } from "@mui/material";

const Root = styled("div")({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  gap: "24px",
});

const Question = styled(Typography)(({ theme }) => ({
  fontWeight: "bold",
}));

const Faq = () => {
  return (
    <Paper sx={{ bgcolor: "#F8FAFB" }}>
      <Box
        sx={{
          p: { xs: 3, md: 10 },
        }}
      >
        <Root>
          <div>
            <Question variant="h6">
              Who is eligible to apply for Australian citizenship?
            </Question>
            <Typography variant="body1">
              To be eligible for Australian citizenship, you must be living in
              Australia on a valid visa for the past 4 years. You must be a
              permanent resident or eligible New Zealand citizen for the past 12
              months. You must have been away from Australia for no more than 12
              months in total in the past 4 years, including no more than 90
              days in total in the past 12 months.{" "}
              <p>
                For children 16 or 17 years old, if meeting this requirement
                would cause significant hardship or disadvantage, you will need
                to provide proof.
              </p>
              Children under 16 do not need to meet the general residence
              requirement but must be permanent residents.
            </Typography>
          </div>

          <div>
            <Question variant="h6">
              How long does it take to get Australian citizenship after passing
              the test?
            </Question>
            <Typography variant="body1">
              The time it takes to get Australian citizenship after passing the
              test can vary. The Department of Home Affairs will need to process
              your application, which can take several months. Once your
              application is approved, you will be invited to attend a
              citizenship ceremony, where you will make the Australian
              Citizenship Pledge and become an Australian citizen.
            </Typography>
          </div>

          <div>
            <Question variant="h6">
              What is the Australian Citizenship Pledge?
            </Question>
            <Typography variant="body1">
              The Australian Citizenship Pledge is a statement that new citizens
              make during a citizenship ceremony. By making the pledge, you
              promise to uphold the laws and values of Australia, and to respect
              the rights and freedoms of all who live in Australia.
            </Typography>
          </div>

          <div>
            <Question variant="h6">
              Do I need to speak English to become an Australian citizen?
            </Question>
            <Typography variant="body1">
              Yes, you need to be able to speak and understand basic English to
              become an Australian citizen. This is because English is the
              national language of Australia, and being able to speak English is
              important for communication and participation in Australian
              society.
            </Typography>
          </div>

          <div>
            <Question variant="h6">
              Can I take the Australian Citizenship Test in a language other
              than English?
            </Question>
            <Typography variant="body1">
              No, the Australian Citizenship Test is only available in English.
              However, you may be eligible for special assistance if you have
              difficulty understanding or communicating in English.
            </Typography>
          </div>

          <div>
            <Question variant="h6">
              What happens if I fail the Australian Citizenship Test three
              times?
            </Question>
            <Typography variant="body1">
              If you fail the Australian Citizenship Test three times, your
              citizenship application may be refused.
            </Typography>
          </div>

          <div>
            <Question variant="h6">
              What is the Australian Citizenship Test, and how do I prepare for
              it?
            </Question>
            <Typography variant="body1">
              The Australian Citizenship Test is a computer-based test that
              assesses your knowledge of Australia and what it means to be an
              Australian citizen. The test covers a range of topics, including
              Australian history, government, and values. To prepare for the
              test, you can study the official Australian Citizenship Test
              resource book, which is available online. There are also many
              practice tests available online that you can take to test your
              knowledge and prepare for the real test.
            </Typography>
          </div>
          <div>
            <Question variant="h6">
              Can I hold dual citizenship in Australia?
            </Question>
            <Typography variant="body1">
              Yes, Australia allows dual citizenship. This means that you can be
              a citizen of Australia and another country at the same time.
              However, some countries may not allow their citizens to hold dual
              citizenship, so it's important to check the laws of your other
              country before applying for Australian citizenship.
            </Typography>
          </div>

          <div>
            <Question variant="h6">
              What are the benefits of being an Australian citizen?
            </Question>
            <Typography variant="body1">
              There are many benefits to being an Australian citizen, including
              the right to vote in elections, access to social security benefits
              and healthcare, and the ability to apply for an Australian
              passport. Being an Australian citizen also gives you a sense of
              belonging and connection to the Australian community and culture.
            </Typography>
          </div>

          <div>
            <Question variant="h6">
              What are the responsibilities of being an Australian citizen?
            </Question>
            <Typography variant="body1">
              As an Australian citizen, you have certain responsibilities, such
              as obeying Australian laws, paying taxes, and participating in
              jury service if required. You also have a responsibility to
              respect the rights and freedoms of others and to contribute to the
              Australian community and society.
            </Typography>
          </div>

          <div>
            <Question variant="h6">
              Can I lose my Australian citizenship?
            </Question>
            <Typography variant="body1">
              Yes, it is possible to lose your Australian citizenship in certain
              circumstances, such as if you obtain citizenship by fraud or false
              pretenses, or if you serve in the armed forces of a country at war
              with Australia. However, the government must follow certain legal
              processes before revoking citizenship, and you have the right to
              appeal the decision.
            </Typography>
          </div>

          <div>
            <Question variant="h6">
              What is the difference between permanent residency and citizenship
              in Australia?
            </Question>
            <Typography variant="body1">
              Permanent residency is a status that allows you to live and work
              in Australia indefinitely. However, as a permanent resident, you
              do not have all of the rights and privileges of an Australian
              citizen, such as the right to vote in elections or apply for an
              Australian passport. Citizenship, on the other hand, gives you
              full rights and privileges as an Australian citizen, including the
              ability to vote and apply for a passport.
            </Typography>
          </div>

          <div>
            <Question variant="h6">
              Can I become an Australian citizen if I have a criminal record?
            </Question>
            <Typography variant="body1">
              Having a criminal record does not necessarily mean that you cannot
              become an Australian citizen. However, the government will
              consider your character as part of your citizenship application,
              and having a criminal record may affect your eligibility. The
              government will look at the nature and seriousness of your
              offense, as well as your rehabilitation and good conduct since the
              offense. It's important to be honest about your criminal record
              when applying for citizenship, as failing to disclose this
              information can result in your application being denied or your
              citizenship being revoked later on.{" "}
            </Typography>
          </div>
          <div>
            <Question variant="h6">
              How long does it take to become an Australian citizen?
            </Question>
            <Typography variant="body1">
              The processing time for Australian citizenship applications varies
              depending on a number of factors, such as the complexity of your
              case and the volume of applications being processed. Generally,
              you can expect the process to take several months to a year or
              more. It's important to apply as early as possible to avoid any
              delays in the processing of your application.
            </Typography>
          </div>

          <div>
            <Question variant="h6">
              How much does it cost to apply for Australian citizenship?
            </Question>
            <Typography variant="body1">
              The fee for applying for Australian citizenship varies depending
              on the type of application and your circumstances. As of 2023, the
              fee for a standard adult application is AUD 490, while the fee for
              a child application if aged 16 or 17 years at the time of
              application is AUD 300. There is no cost for a child 15 years or
              younger applying on the same form as a parent. There are also
              additional fees for other types of applications, such as resuming
              citizenship or renouncing citizenship.
            </Typography>
          </div>

          <div>
            <Question variant="h6">
              What happens if my citizenship application is denied?
            </Question>
            <Typography variant="body1">
              If your citizenship application is denied, you have the right to
              appeal the decision. The appeals process can be lengthy and
              complex, and you may need to seek legal advice to help you
              navigate it. If your appeal is unsuccessful, you may still be able
              to apply for citizenship again in the future, depending on the
              reasons for your initial rejection.
            </Typography>
          </div>
          <div>
            <Question variant="h6">
              Can I get help preparing for the Australian Citizenship Test?
            </Question>
            <Typography variant="body1">
              Yes, there are many resources available to help you prepare for
              the Australian Citizenship Test. The official resource book
              provides a comprehensive guide to the topics covered in the test,
              and there are also many online practice tests and study materials
              available. You may also be able to find study groups or classes in
              your local community to help you prepare for the test.{" "}
            </Typography>
          </div>
          <div>
            <Question variant="h6">
              What documents do I need to provide when applying for Australian
              citizenship?
            </Question>
            <Typography variant="body1">
              The specific documents required for your citizenship application
              will depend on your individual circumstances, but typically you
              will need to provide proof of your identity, such as a passport or
              birth certificate, as well as evidence of your residency and good
              character, such as police clearance certificates and character
              references. You may also need to provide additional documents,
              such as evidence of your English language proficiency or proof of
              any name changes.
            </Typography>
          </div>

          <div>
            <Question variant="h6">
              What are the benefits of becoming an Australian citizen?
            </Question>
            <Typography variant="body1">
              There are many benefits to becoming an Australian citizen,
              including the right to vote and stand for public office, access to
              certain social security benefits, and the ability to travel on an
              Australian passport. Citizenship also provides a sense of
              belonging and connection to the community, as well as the security
              of knowing that you have a permanent right to live and work in
              Australia.
            </Typography>
          </div>

          <div>
            <Question variant="h6">
              Can I hold dual citizenship in Australia?
            </Question>
            <Typography variant="body1">
              Yes, Australia allows dual citizenship, which means you can hold
              citizenship of Australia as well as another country. However, it's
              important to check the citizenship laws of your other country, as
              some countries do not allow dual citizenship or may require you to
              give up your other citizenship when you become an Australian
              citizen.
            </Typography>
          </div>
          <div>
            <Question variant="h6">
              Do I need to take an English language test to become an Australian
              citizen?
            </Question>
            <Typography variant="body1">
              In most cases, you will need to demonstrate your English language
              proficiency when applying for Australian citizenship. The
              citizenship test is part of the application process for most
              applicants for Australian citizenship by conferral. Passing the
              citizenship test will show you have a basic knowledge of the
              English language.
            </Typography>
          </div>
        </Root>
      </Box>
    </Paper>
  );
};

export default Faq;
