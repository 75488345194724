import React, { useState } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Question } from "./models/Question";

import {
  Autocomplete,
  Chip,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import { saveQuestion } from "./FirebaseAPI";
import { Category } from "./models/Category";

export default function EditQuestionDialog(props: {
  open: boolean;
  close: Function;
  question: Question;
  categories: Map<string, Category>;
  id?: string;
}) {
  const [question, setQuestion] = useState(props.question);

  React.useEffect(() => {
    setQuestion(props.question);
  }, [props]);

  const handleClose = () => {
    props.close(false);
  };

  const reset = () => {
    setQuestion(props.question);
  };

  const handleChangeAnswer = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    index: number
  ) => {
    let options = [...question.options];
    options[index] = e.target.value;
    setQuestion({
      ...question,
      options,
    });
  };

  const handleChangeDescription = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setQuestion({
      ...question,
      description: e.target.value,
    });
  };

  const handleChangeExplanation = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setQuestion({
      ...question,
      explanation: e.target.value,
    });
  };

  const selectCorrectAnswer = (e: SelectChangeEvent) => {
    setQuestion({
      ...question,
      answer: e.target.value,
    });
  };

  const handleCategoryChange = (e: SelectChangeEvent) => {
    setQuestion({
      ...question,
      heading: e.target.value,
    });
  };

  const handleTagChange = (
    e: React.SyntheticEvent,
    newValue: Array<string>
  ) => {
    setQuestion({
      ...question,
      tags: newValue,
    });
  };

  const handleSave = () => {
    const options = question.options.filter((opt) => opt.trim());
    const qt = { ...question, options };
    saveQuestion(qt, props.id).then(() => handleClose());
  };

  return (
    <div>
      <Dialog open={props?.open} onClose={handleClose}>
        <DialogTitle>{question ? "Edit" : "Add"} Question</DialogTitle>
        <DialogContent>
          <DialogContentText></DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="description"
            label="Question"
            fullWidth
            rows={2}
            multiline
            onChange={handleChangeDescription}
            value={question?.description}
          />
          {question?.options.map((option, index) => (
            <TextField
              margin="dense"
              id="name"
              fullWidth
              label={"choice " + (1 + index)}
              onChange={(e) => handleChangeAnswer(e, index)}
              value={option}
              size="small"
              key={"answer" + index}
            />
          ))}
          <FormControl fullWidth size="small" margin="dense">
            <InputLabel id="select-answer-label">Answer</InputLabel>
            <Select
              labelId="select-answer-label"
              id="select-answer"
              value={question.answer !== undefined ? "" + question.answer : ""}
              label="Answer"
              onChange={selectCorrectAnswer}
            >
              {question?.options
                .filter((opt) => opt.trim())
                .map((option, index) => (
                  <MenuItem value={"" + index} key={option}>
                    {option}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
          <FormControl fullWidth size="small" margin="dense">
            <InputLabel id="select-category-label">Category</InputLabel>
            <Select
              labelId="select-category-label"
              id="select-category"
              value={question?.heading ? question.heading : ""}
              label="Category"
              onChange={handleCategoryChange}
            >
              {Array.from(props.categories.entries()).map((entry) => (
                <MenuItem value={entry[0]} key={entry[0]}>
                  {entry[1].label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl fullWidth size="small" margin="dense">
            <Autocomplete
              fullWidth
              size="small"
              multiple
              id="fixed-tags-demo"
              value={question.tags || []}
              onChange={(event, newValue) => handleTagChange(event, newValue)}
              options={props.categories.get(question.heading)?.tags || ["none"]}
              getOptionLabel={(option) => option}
              renderTags={(tagValue, getTagProps) =>
                tagValue.map((option, index) => (
                  <Chip label={option} {...getTagProps({ index })} />
                ))
              }
              renderInput={(params) => (
                <TextField {...params} label="Fixed tag" placeholder="tags" />
              )}
            />
          </FormControl>
          <TextField
            margin="dense"
            id="explanation"
            label="Explanation"
            fullWidth
            rows={2}
            multiline
            onChange={handleChangeExplanation}
            value={question?.explanation}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={reset}>Reset</Button>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSave}>Save</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
