import React from "react";
import { Box } from "@mui/system";
import background from "./icons/sydney-svgrepo-com.svg";
import { Button, Grid, Paper, Typography } from "@mui/material";
import { logevent } from "./FirebaseAPI";
import { useNavigate } from "react-router-dom";
import HowToPrepare from "./HowToPrepare";

const Header = () => (
  <Typography
    variant="h4"
    align="center"
    gutterBottom
    sx={{ color: "#1A2027" }}
  >
    Welcome to Australian Citizenship Test Practice!
  </Typography>
);

const Section = (props: { title: string; text: string }) => (
  <Grid item>
    <Paper
      sx={{
        height: 250,
        width: 300,
        p: 5,
        backgroundColor: (theme) =>
          theme.palette.mode === "dark" ? "#1A2027" : "#fff",
      }}
    >
      <Typography variant="h6" align="center" gutterBottom>
        {props.title}
      </Typography>
      <Typography align="center">{props.text}</Typography>
    </Paper>
  </Grid>
);

export default function Home() {
  const navigate = useNavigate();

  const freePracticeTest = () => {
    logevent("practice_test", { source: "home_page" });
    navigate("/australian-citizenship-test");
  };

  return (
    <Box>
      <Box
        sx={{
          position: "relative",
          backgroundColor: "#00843D",
          color: "#fff",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          backgroundImage: `url(${background})`,
          minHeight: "500px",
        }}
      >
        <Box
          sx={{
            position: "absolute",
            top: 0,
            bottom: 0,
            right: 0,
            left: 0,
          }}
        />
        <Grid container>
          <Grid item md={6}>
            <Box
              sx={{
                position: "relative",
                p: { xs: 3, md: 6 },
                pr: { md: 0 },
              }}
            >
              <Typography
                component="h1"
                variant="h3"
                color="inherit"
                gutterBottom
              >
                Australian Citizenship Test updated for 2024
              </Typography>
              <Typography variant="h5" color="inherit" paragraph>
                200+ QUESTIONS
              </Typography>
              <Button
                variant="contained"
                color="secondary"
                onClick={freePracticeTest}
              >
                FREE PRACTICE TEST
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box
        sx={{
          position: "relative",
          bgcolor: "#FFCD00",
          color: "#fff",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          minHeight: "500px",
          p: 5,
        }}
      >
        <Box
          sx={{
            position: "absolute",
            top: 0,
            bottom: 0,
            right: 0,
            left: 0,
          }}
        />
        <Grid container spacing={8} justifyContent="center">
          <Grid item xs={12} md={6}>
            <Header />
          </Grid>
          <Grid item xs={12}>
            <Grid container justifyContent="center" spacing={6}>
              <Section
                title="Are you preparing to become an Australian citizen?"
                text="Our website provides a comprehensive and free online practice test to help you prepare for the official Australian citizenship test. Our practice test is designed to simulate the real test and provide you with the knowledge and confidence to pass on your first attempt."
              />
              <Section
                title="What is the Australian citizenship test?"
                text="The Australian citizenship test is a computer-based test that assesses your knowledge of Australia and its culture, history, geography, government, and values. The test consists of 20 multiple-choice questions, and you must score at least 75% to pass."
              />
              <Section
                title="Why choose our practice test?"
                text="Our practice test is free and easy to use. You can take the test as many times as you want, and you'll receive instant feedback on your answers. We also provide detailed explanations for each question, so you can understand the correct answer and learn from any mistakes."
              />
            </Grid>
            <Grid
              item
              xs={12}
              md={12}
              container
              justifyContent="center"
              sx={{ p: 5 }}
            >
              <Button
                variant="contained"
                color="success"
                onClick={freePracticeTest}
              >
                PRACTICE TESTS
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <HowToPrepare />
      <Grid
        item
        xs={12}
        md={12}
        container
        justifyContent="center"
        sx={{ p: 5 }}
      >
        <Button variant="contained" color="success" onClick={freePracticeTest}>
          PRACTICE TESTS
        </Button>
      </Grid>
    </Box>
  );
}
