import {
  Box,
  Grid,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
} from "@mui/material";
import * as React from "react";
import { useLoaderData, useNavigate } from "react-router-dom";
import { getAllQuestion, getAllTests } from "./FirebaseAPI";
import { Test } from "./models/Test";
import { ReactComponent as KangarooIcon } from "./icons/australian-kangaroo-svgrepo-com.svg";
import { ReactComponent as AflIcon } from "./icons/afl-australia-australian-svgrepo-com.svg";
import { ReactComponent as QwalaIcon } from "./icons/koala-svgrepo-com.svg";
import { ReactComponent as BallIcon } from "./icons/australia-australian-ball-svgrepo-com.svg";
import { ReactComponent as BridgeIcon } from "./icons/australia-australian-bridge-svgrepo-com.svg";
import { ReactComponent as BuildingIcon } from "./icons/australia-australian-building-svgrepo-com.svg";
import { ReactComponent as ChristmasIslandIcon } from "./icons/christmas-island-australia-svgrepo-com.svg";
import { ReactComponent as OperaHouseIcon } from "./icons/sydney-opera-house-australia-svgrepo-com.svg";
import { ReactComponent as TweleveApostleIcon } from "./icons/twelve-apostles-australia-rocks-svgrepo-com.svg";
import { ReactComponent as CoffeeCupIcon } from "./icons/coffee-cup-svgrepo-com.svg";
import CircularProgressWithLabel from "./components/CircularProgressWithLabel";
import { TestExecution } from "./models/TestExecution";
import DonationDialog from "./components/DonationDialog";

const icons = [
  <KangarooIcon width="30" height="30" fill="#F68028" />,
  <AflIcon width="30" height="30" fill="#CB631F" />,
  <QwalaIcon width="30" height="30" />,
  <BallIcon width="30" height="30" fill="#5BA344" />,
  <BridgeIcon width="30" height="30" fill="#CB631F" />,
  <BuildingIcon width="30" height="30" fill="#5BA344" />,
  <ChristmasIslandIcon width="30" height="30" fill="#5BA344" />,
  <OperaHouseIcon width="30" height="30" fill="#5BA344" />,
  <TweleveApostleIcon width="30" height="30" fill="#F68028" />,
  <CoffeeCupIcon width="30" height="30" fill="#CB631F" />,
];

const getTestStatus = (id: string): TestExecution => {
  const savedStats = localStorage.getItem(id);
  return savedStats
    ? JSON.parse(decodeURIComponent(atob(savedStats)))
    : ({} as TestExecution);
};

const getTestProgress = (id: string): number => {
  const status = getTestStatus(id);
  return ((status?.states?.length || 0) / 20) * 100;
};

const getTestWrong = (id: string): number => {
  const status = getTestStatus(id);
  return ((status?.states?.filter((f) => !f.correct).length || 0) / 20) * 100;
};

const ListAllTest = () => {
  const navigate = useNavigate();
  const loadedData = useLoaderData() as Array<any>;
  const [open, setOpen] = React.useState(false);

  const [tests, setTests] = React.useState(loadedData[1] as Map<string, Test>);

  const handleClickOpen = (id: string, test: Test, progress: number) => {
    if (id) {
      if (progress < 100) {
        if (
          id == "citizenship-test-19" &&
          !localStorage.getItem("coffee_answer")
        ) {
          setOpen(true);
        } else {
          navigate("/australian-citizenship-test/" + id);
        }
      } else {
        navigate("/result", { state: { result: getTestStatus(id) } });
      }
    }
  };

  const handleClickClose = () => {
    setOpen(false);
  };

  const textStyle = (index: string) => {
    return {
      textDecorationColor: "primary.main",
      mt: 1,
      mb: 1,
      bgcolor: "background.paper",
      borderRadius: "5px",
    };
  };

  return (
    <Paper variant="elevation" sx={{ bgcolor: "#F8FAFB" }}>
      <Grid container justifyContent="center">
        <Grid item md={8} xs={12}>
          <Box
            sx={{
              position: "relative",
              p: { xs: 3, md: 3 },
              pt: 0,
              pb: 0,
            }}
          >
            <List sx={{ width: "100%" }}>
              {Array.from(tests.entries()).map((entry, index) => {
                const labelId = `checkbox-list-label-${entry[0]}`;
                let progress = getTestProgress(entry[0]);
                let wrong = getTestWrong(entry[0]);
                return (
                  <ListItem
                    key={entry[0]}
                    disablePadding
                    sx={textStyle("")}
                    secondaryAction={
                      <CircularProgressWithLabel
                        value={progress}
                        wrong={wrong}
                      />
                    }
                  >
                    <ListItemButton
                      role={undefined}
                      onClick={() =>
                        handleClickOpen(entry[0], entry[1], progress)
                      }
                      dense
                    >
                      <ListItemIcon sx={{ p: 2 }}>
                        {icons[index % 10]}
                      </ListItemIcon>
                      <ListItemText id={labelId} primary={entry[1].name} />
                    </ListItemButton>
                  </ListItem>
                );
              })}
            </List>
          </Box>

          <Box
            sx={{
              position: "relative",
              p: { xs: 3, md: 6 },
              pl: { xs: 0 },
              pr: { xs: 0 },
            }}
          ></Box>
        </Grid>
      </Grid>
      <DonationDialog
        close={handleClickClose}
        open={open}
        id="citizenship-test-12"
      ></DonationDialog>
    </Paper>
  );
};

export default ListAllTest;

export async function loader() {
  const tests = await getAllTests();
  const questions = await getAllQuestion();
  return [questions, tests];
}
