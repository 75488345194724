export class Test {
  "questions": Array<string>;
  "name": string;
  "active": boolean;

  constructor() {
    this.name = "";
    this.questions = [];
    this.active = true;
  }
}
