export class Question {
  "description": string;
  "options": Array<string>;
  "tags": Array<string>;
  "answer": string;
  "heading": string;
  "explanation"?: string;
  constructor() {
    this.description = "";
    this.options = [];
    this.answer = "";
    this.heading = "";
    this.explanation = "";
    this.tags = [];
  }
}
