import * as React from "react";
import LinearProgress, {
  LinearProgressProps,
} from "@mui/material/LinearProgress";
import Box from "@mui/material/Box";

export default function LinearProgressWithLabel(
  props: LinearProgressProps & { value: number }
) {
  return (
    <Box sx={{ display: "flex", alignItems: "center", pb: 1 }}>
      <Box sx={{ width: "100%" }}>
        <LinearProgress
          variant="determinate"
          sx={{ height: { md: 7, xs: 4 }, borderRadius: 5 }}
          color={props.value < 100 ? "warning" : "success"}
          {...props}
        />
      </Box>
    </Box>
  );
}
