import * as React from "react";
import Toolbar from "@mui/material/Toolbar";
import { Link } from "react-router-dom";
import { Button, Typography } from "@mui/material";
import MailOutlineIcon from "@mui/icons-material/MailOutline";

interface HeaderProps {
  title: string;
}

export default function HeaderSimple(props: HeaderProps) {
  const { title } = props;
  return (
    <React.Fragment>
      <Toolbar
        sx={{
          borderBottom: 1,
          borderColor: "divider",
          bgcolor: "#283D84",
          color: "white",
        }}
      >
        <MailOutlineIcon sx={{ fill: "#5BA344" }} />

        <Typography
          variant="subtitle1"
          color="inherit"
          align="left"
          noWrap
          sx={{ flex: 1, ml: 2 }}
        >
          {title}
        </Typography>
        <Button variant="outlined" size="small" href="/">
          Home
        </Button>
      </Toolbar>
    </React.Fragment>
  );
}
