import { useEffect, useState, useContext } from "react";
import { Outlet } from "react-router-dom";
import { AuthContext } from "./Auth";
import Footer from "./Footer";
import Header from "./Header";

const sections = [
  { title: "HOME", url: "/" },
  { title: "PRACTICE TESTS", url: "/australian-citizenship-test" },
  { title: "FAQS", url: "/faq" },
];

const adminUsers = [
  "OYsO7eysivarhJdneQspM3VOO4i1",
  "SAy7SVKz2gMC0r1eg2ju1i52LoM2",
];

export const RootLayout = () => {
  const { currentUser } = useContext(AuthContext);
  const [menu, setMenu] = useState(sections);

  useEffect(() => {
    if (currentUser && adminUsers.indexOf(currentUser?.uid) >= 0) {
      setMenu([
        ...sections,
        { title: "Manage Tests", url: "/admin/manage-tests" },
        { title: "Manage Questions", url: "/admin/manage-questions" },
      ]);
    } else {
      setMenu(sections);
    }
  }, [currentUser]);

  return (
    <>
      <Header title="support@futurecitizens.com.au" sections={menu} />
      <Outlet />
      <Footer
        title="Made for the Future Australian Citizens"
        domain="www.futurecitizens.com.au"
        description="Online Practice Tests – pass your Australian Citizenship Test"
      />
    </>
  );
};
