import * as React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { AuthContext } from "./Auth";

const PrivateRoute = ({
  children,
  path,
}: {
  children: JSX.Element;
  path: string;
}) => {
  const { currentUser } = React.useContext(AuthContext);
  console.log("This is auth", currentUser);
  return currentUser ? (
    children
  ) : (
    <Navigate to="/login" replace state={{ path: path }} />
  );
};

export default PrivateRoute;
