import {
  Button,
  Chip,
  Fab,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import * as React from "react";
import { useLoaderData } from "react-router-dom";
import { getAllCategories, getAllQuestion } from "./FirebaseAPI";
import { Question } from "./models/Question";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";

import EditQuestionDialog from "./EditQuestionDialog";
import { Box } from "@mui/system";
import { Category } from "./models/Category";

const createDefaultQuestion = (question: Question) => {
  const options = question.options ? [...question.options] : [];
  var size = 4 - options.length;
  for (var i = 0; i < size; i++) {
    options.push("");
  }
  return { ...question, options };
};

const ManageQuestions = () => {
  const loadedData = useLoaderData() as Array<any>;
  const [allQuestions, setAllQuestions] = React.useState(
    loadedData[0] as Map<string, Question>
  );
  const [questions, setQuestions] = React.useState(
    loadedData[0] as Map<string, Question>
  );
  const categories = loadedData[1] as Map<string, Category>;

  const [open, setOpen] = React.useState(false);
  const [question, setQuestion] = React.useState<Question>(new Question());
  const [questionId, setQuestionId] = React.useState<string>();
  const [searchStr, setSearchStr] = React.useState<string>();
  const [categoryFilter, setCategoryFilter] = React.useState<string>();

  const handleClickOpen = (question: Question, id?: string) => {
    setQuestion(createDefaultQuestion(question));
    setQuestionId(id);
    setOpen(true);
  };

  const handleClickClose = () => {
    setOpen(false);
    getAllQuestion().then((qs) => setAllQuestions(qs));
  };

  const mobileButton = {
    position: "fixed",
    bottom: 16,
    left: 16,
    display: { md: "none" },
  };

  const desktopButton = {
    position: "fixed",
    bottom: 16,
    right: 16,
    display: { xs: "none", md: "flex" },
  };

  const handleSearch = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setSearchStr(e.target.value);
  };

  React.useEffect(() => {
    let questions = allQuestions;
    if (searchStr) {
      const map1 = new Map<string, Question>();
      questions.forEach((value: Question, key: string) => {
        if (
          value.description.toLowerCase().indexOf(searchStr.toLowerCase()) >
            -1 ||
          value.tags?.filter(
            (f) => f.toLowerCase().indexOf(searchStr.toLowerCase()) > -1
          ).length
        ) {
          map1.set(key, value);
        }
      });
      questions = map1;
    }

    if (categoryFilter) {
      const map1 = new Map<string, Question>();
      questions.forEach((value: Question, key: string) => {
        if (value.heading === categoryFilter) {
          map1.set(key, value);
        }
      });
      questions = map1;
    }

    setQuestions(questions);
  }, [searchStr, categoryFilter, allQuestions]);

  const handleCategoryChange = (e: SelectChangeEvent) => {
    setCategoryFilter(e.target.value);
  };

  return (
    <Paper>
      <Box
        sx={{
          position: "relative",
          pl: 2,
          pr: 2,
        }}
      >
        <FormControl fullWidth size="small" margin="dense">
          <TextField
            autoFocus
            id="description"
            label="Search Questions"
            onChange={handleSearch}
          />
        </FormControl>
      </Box>
      <Box
        sx={{
          position: "relative",
          pl: 2,
          pr: 2,
        }}
      >
        <FormControl fullWidth size="small" margin="dense">
          <InputLabel id="select-category-label">Filter By Category</InputLabel>
          <Select
            labelId="select-category-label"
            id="select-category"
            label="Filter By Category"
            onChange={handleCategoryChange}
          >
            <MenuItem value="" key="-1">
              None
            </MenuItem>
            {Array.from(categories.entries()).map((entry) => (
              <MenuItem value={entry[0]} key={entry[0]}>
                {entry[1].label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      <Box
        sx={{
          position: "relative",
          p: 2,
        }}
      >
        Total Questions: {questions.size}
      </Box>
      <TableContainer component={Paper} sx={{ maxHeight: { md: 500, sx: 1 } }}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Question</TableCell>
              <TableCell align="left">Category</TableCell>
              <TableCell align="right">Details </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Array.from(questions.entries()).map((entry) => (
              <TableRow
                key={entry[0]}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {entry[1].description}
                  <Box>
                    {entry[1].tags?.map((tag) => (
                      <Chip label={tag} variant="outlined" size="small" />
                    ))}
                  </Box>
                </TableCell>
                <TableCell align="left">
                  {categories.get(entry[1].heading)?.label}
                </TableCell>
                <TableCell align="right">
                  <IconButton
                    color="primary"
                    aria-label="upload picture"
                    component="label"
                    onClick={() => handleClickOpen(entry[1], entry[0])}
                  >
                    <EditIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Stack
        spacing={2}
        direction="row"
        display="flex"
        justifyContent="flex-end"
        flexDirection="column"
        p={2}
        sx={desktopButton}
      >
        <Button
          variant="contained"
          onClick={() => handleClickOpen(new Question(), undefined)}
          fullWidth
        >
          Add a Question
        </Button>
      </Stack>
      <Fab
        color="primary"
        aria-label="add"
        sx={mobileButton}
        onClick={() => handleClickOpen(new Question(), undefined)}
      >
        <AddIcon />
      </Fab>
      <EditQuestionDialog
        open={open}
        close={handleClickClose}
        question={question}
        categories={categories}
        id={questionId}
      />
    </Paper>
  );
};

export default ManageQuestions;

export async function loader() {
  const categories = await getAllCategories();
  const questions = await getAllQuestion();
  return [questions, categories];
}
